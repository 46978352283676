import React, { useEffect, useState } from 'react'
import { globalDeleteService, globalGetService, globalPostService } from '../../../utils_v2/globalApiServices'
import { DeletePopUp, EmptyCollection, PageLoader, TableListComp } from '../../../shared_elements'
import { summaryInviceHd } from '..'
import NumericLabel from 'react-pretty-numbers';
import { Grid, Paper, TableCell, TableRow, Button, Tooltip } from '@material-ui/core'
import moment from 'moment'
import { displayDateFormat, displayDateFormatShort, invoiceStatusList } from '../../../constants'
import { checkApiStatus, checkPermission, dateTransform, getCurrencyFormat } from '../../../utils_v2'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { browserHistory } from 'react-router';
import { STableLoader } from '../../../shared_elements/loaders';
import StatusChangeDialog from './StatusChangeDialog';
import { GetApp } from '@material-ui/icons';
import { globalExportService, globalPutService } from '../../../globalServices';
import { downloadFileType } from '../../../utils';
import GenerateSummaryDialog from './GenerateSummaryDialog';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useSnackbar } from 'notistack';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
const SummartInvoiceList = ({ item, index, setStatusChangeList, exportInvoice, getSummaryInvoiceList }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const [circularLoader, setCircularloader] = useState(false)
  const [showMore, setShowMore] = useState(false);

  const unlinkInvoices = (item) => {
    setCircularloader(true);
    globalDeleteService(`/contracts/summary-invoice/${item.slug}/unlink/`)
      .then(response => {
        if (checkApiStatus(response)) {
          setCircularloader(false);
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          getSummaryInvoiceList()
          setModal(false)
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
  const displayAssets = showMore ? item.assets :item.assets.slice(0,1);
  const toggleShowMore = () => setShowMore(!showMore);
  

  return (
    <>
      <TableRow hover tabIndex={-1} style={{ cursor: 'pointer' }}>
      <TableCell className="asset-info">
      <div className="summary-wrapper" style={{ flexWrap: 'nowrap' }}>
        <div className="asset" style={{marginLeft:'0px'}}>
          {displayAssets.map((asset, index) => (
            <div key={index}>
              {asset}{index < displayAssets.length - 1 ? ',' : ''}
            </div>
          ))}
        </div>
      </div>
      {item?.assets .length > 1 && (
        <div className="view-more-toggle" onClick={toggleShowMore}>
          {showMore ? 'View Less' : 'View More'}
        </div>
      )}
    </TableCell>
        <TableCell >{item.lessor && item.lessor ? item.lessor : '--'}</TableCell>
        <TableCell >
          {item.lessee && item?.lessee.logo ? <img style={{ width: "60px" }} src={item.lessee && item?.lessee} alt="Lessee Logo" /> : <span>{item.lessee && item.lessee ? item.lessee : '--'}</span>}
        </TableCell>
        <TableCell >{item.invoice_number}</TableCell>
        <TableCell>
          <p className={item.status?.value && invoiceStatusList[item.status?.value] + ' invoice-status'}>{item.status && item.status?.label ? item.status?.label.toUpperCase() : '--'}
            {(item?.status?.value == 1 || item?.status?.value == 2 || item.status?.value == 5) && checkPermission('contracts', 'summary_invoice', 'STS') ?
              <i className="fa fa-caret-down" style={{ float: 'right' }}
                onClick={(e) => setStatusChangeList({ data: item, modal: true })}
              >
              </i> : null
            }
          </p>
          <span className="payment-text">
            {((item.status?.value == 2) && item.in_due) ? <span className="invoice-status-payment" >{"Overdue By " + dateTransform(item.due_date)}</span> : ''}
          </span>
          {
            item.status?.value == 5 && item.partial_paid_date ?
              <span className="payment-text">
                <span>Partial Payment: <NumericLabel params={getCurrencyFormat({ currency: item.currency, shortFormat: false })}>{item.partial_paid_amount ? item.partial_paid_amount : '--'}</NumericLabel><br /></span>
                <span>Payment Date: {moment(item.partial_paid_date).format(displayDateFormat)}</span>
                {moment(item.partial_paid_date).diff(moment(item.due_date), 'days') > 0 ? <span className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date)}</span> : ''}
              </span> : null
          }
          {
            item.status?.value == 3 && item.paid_date ?
              <span className="payment-text">
                Payment Date: {moment(item.paid_date).format(displayDateFormat)}
                {moment(item.paid_date).diff(moment(item.due_date), 'days') > 0 ? <span className="invoice-status-payment">{"Payment late by " + dateTransform(item.due_date, item.paid_date)}</span> : ''}
              </span> :
              null
          }
        </TableCell>
        <TableCell>{item.invoice_type && item.invoice_type.label ? item.invoice_type.label : '--'}</TableCell>
        <TableCell>{item.bank_name}</TableCell>
        <TableCell><NumericLabel params={getCurrencyFormat({ currency: item?.currency, shortFormat: false })}>{item.amount}</NumericLabel></TableCell>
        <TableCell style={{ width: "170px" }}>{item.start_date ? moment(item.start_date).format(displayDateFormat) : '--'} <br /><span style={{ marginLeft: '30px' }}>to</span><br /> {item.end_date ? moment(item.end_date).format(displayDateFormat) : '--'}</TableCell>
        <TableCell>{item.date ? moment(item.date).format(displayDateFormat) : '--'}</TableCell>
        <TableCell>{item.due_date ? moment(item.due_date).format(displayDateFormat) : '--'}</TableCell>
        <TableCell align='right'>
        {checkPermission('contracts','summary_invoice','R')?
          <Tooltip title='View'>
            <VisibilityIcon color='primary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => browserHistory.push(`/invoices/summary/${item?.slug}`)} />
          </Tooltip>:null}
          {checkPermission('contracts','summary_invoice','EXP')?
          <Tooltip title='Export'>
            <GetApp color='primary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => exportInvoice(item)} />
          </Tooltip>:null}
          {item?.status?.value == 1 && checkPermission('contracts','summary_invoice','D')?
            <Tooltip title='Delete' placement='left-end' >
              <DeleteOutlineIcon color='secondary' fontSize='small' style={{ cursor: 'pointer' }} onClick={() => setModal(true)} />
            </Tooltip> : null}
        </TableCell>
      </TableRow>
      {<DeletePopUp
        modal={modal}
        toggleModalFn={() => { setModal(false) }}
        title="Delete Summmary Invoice"
        content={<h4>Are you sure you want to Delete?</h4>}
        deleteRecordFn={() => unlinkInvoices(item)}
        confirmText='Delete'
        circularloader={circularLoader}
      />}
    </>
  )
}

export default function SummaryInvoice() {
  const [summaryList, setSummaryList] = useState({})
  const [filter, setFilter] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [skeletonLoader, setSkeletonLoader] = useState(false)
  const [statusChange, setStatusChangeList] = useState(false)
  const [generateSummary, setGenerateSummary] = useState(false);
  // const [modal, setModal] = useState(false);
  useEffect(() => {
    getSummaryInvoiceList({}, 'skeletonLoader')
  }, [])
  const getSummaryInvoiceList = (query, loaderType) => {
    if (loaderType == 'skeletonLoader') {
      setSkeletonLoader(true)
    } else {
      setLoading(true)
    }
    globalGetService(`contracts/summary-invoice/list/`, query)
      .then(response => {
        setSkeletonLoader(false)
        setLoading(false)
        setSummaryList(response.data?.data)
      })
  }
  const exportInvoice = (item) => {
    setLoading(true)
    globalExportService(`contracts/summary-invoice/${item.slug}/?download=pdf`)
      .then(response => {
        setLoading(false)
        downloadFileType(response, `Summary_invoice_${item?.invoice_number}.`, 'pdf')
      })
  }
  return (
    <div>
      {isLoading ? <PageLoader /> : null}
      {skeletonLoader ? <STableLoader count={8} /> :
        checkPermission('contracts','summary_invoice','R')?
        <>
          <Paper square style={{ padding: '6px 10px' }}>
            <Grid container spacing={1} alignItems='center' style={{ justifyContent: "flex-end" }}>
              <Grid>
                {checkPermission('contracts','summary_invoice','C')?
                <Button size='small' variant='outlined' color='primary' onClick={() => setGenerateSummary({ modal: true })} >Generate Summary Invoice</Button>
                :null}
              </Grid>
            </Grid>
          </Paper>
          <TableListComp
            heads={summaryInviceHd}
            bulkOperation={false}
            // sort_by={sort_by}
            maxheight={(window.innerHeight - 225) + 'px'}
            data={summaryList?.list?.map((item, index) =>
              <SummartInvoiceList
                setStatusChangeList={setStatusChangeList}
                index={index}
                exportInvoice={exportInvoice}
                item={item}
                getSummaryInvoiceList={getSummaryInvoiceList}
              />
            )
            }
            onChangePage={(event, newPage) => getSummaryInvoiceList({ ...filter, page: newPage + 1, per_page: summaryList.pagination.per_page }, 'pageLoader')}
            onChangeRowsPerPage={(event) => getSummaryInvoiceList({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
            pagination={summaryList.pagination}
            noRecord={summaryList?.list?.length || isLoading ? null :
              <EmptyCollection
                title="No records found"
              />
            }
          />
        </>:<RestrictedAccess/>
      }
      {
        statusChange?.modal ?
          <StatusChangeDialog
            setStatusChangeList={setStatusChangeList}
            invStatus={statusChange?.data?.status}
            invData={statusChange?.data}
            getResponseBack={getSummaryInvoiceList}
          />
          : null
      }

      <GenerateSummaryDialog
        setGenerateSummary={setGenerateSummary}
        generateSummary={generateSummary}
        getResponseBack={getSummaryInvoiceList}
      />
    </div>
  )
}
