import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../../../globalServices/';
import { browserHistory } from 'react-router';
import { toastFlashMessage,downloadFileType } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel'
import { CTR_SHORT_INFO } from '../../Listing/actions'
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const UtilsAssetViewAc = (params, type) => {
  return (dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    globalGetService('console/contract/'+params.type+'/'+ params.aircraft_slug + '/',{})
    .then(response => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: CTR_SHORT_INFO,
          payload: response.data.data
        });
        let assetInfo = response.data.data;
        let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
        trackActivity('Page Visited',{
          page_title: 'Utilization',
          application_module: 'Contracts',
          asset: asset_serial_number,
        })
        if(type == 'list'){
          // dispatch(utilsListAc(response.data.data.contract.slug, {}));
          // dispatch(utilsTrendsAc(response.data.data.contract.slug));
          // dispatch(utilsAvgAc(response.data.data.contract.slug));
          // dispatch(utilsInitiateAc(response.data.data.contract.slug));
          // dispatch(utilsMajorAssemblyAc(response.data.data.contract.slug));
          // dispatch(getRentalUtilizationAc(response.data.data.contract.slug));
        }else{
          // dispatch(utilsInitiateAc(response.data.data.contract.slug));
        }
      }
    });
  }
}

export const exportSampleUtilizationAc = (ownProps, contract) => {
 let fileType = 'xls'
  return (dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    globalExportService(`/console/${ownProps.type}/${ownProps.aircraft_slug}/utilizations/sample-download/` )
    .then(response => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
      if(response && response.size > 0){
        if (contract.asset_type === 2){
          downloadFileType(response, 'ESN'+contract.esn+'-Utilization-Import.', fileType)
        }else if(contract.asset_type === 3){
          downloadFileType(response, 'APU'+contract.serial_number+'-Utilization-Import.', fileType)
        }else if(contract.asset_type === 4){
          downloadFileType(response, 'LG'+contract.serial_number+'-Utilization-Import.', fileType)
        }else if(contract.asset_type === 5){
          downloadFileType(response, 'Propeller'+contract.serial_number+'-Utilization-Import.', fileType)
        }else if(contract.asset_type === 1){
          downloadFileType(response, 'MSN'+contract.msn+'-Utilization-Import.', fileType)
        }else if (contract.asset_type === 6) {
          downloadFileType(response, 'FAN' + contract.esn+ '-Utilization-Import.', fileType)
        }else if (contract.asset_type === 7) {
          downloadFileType(response, 'HPC' + contract.esn + '-Utilization-Import.', fileType)
        }else if (contract.asset_type === 8) {
          downloadFileType(response, 'HPT' + contract.esn + '-Utilization-Import.', fileType)
        }else if (contract.asset_type === 9) {
          downloadFileType(response, 'LPT' + contract.esn + '-Utilization-Import.', fileType)
        }
      }
    });
  }
}

export const getRentalUtilizationAc = (props, filters, flag) => {
  return(dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    globalGetService(`contracts/${props.params.contract_slug}/rental-utilization/`, filters)
    .then(response => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
      if(response.data.statusCode === 200){
        if(flag){
          dispatch(utilsInitiateAc(props))
        }
        dispatch({
          type: actions.RENTAL_UTILS_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const utilsTrendsAc = (props) => {
  return (dispatch) => {
    globalGetService(`contracts/${props.params.contract_slug}/utilizations/trend/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UTILS_TRENDS,
          payload: response.data.data
        })
      }
    })
  }
}
export const utilsAvgAc = (props) => {
  return (dispatch) => {
    globalGetService(`contracts/${props.params.contract_slug}/utilizations/average/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UTILS_AVERAGE,
          payload: response.data.data
        });
      }
    })
  }
}

export const utilsListAc = (props, queryParams) => {
  return (dispatch) => {
    if(Object.keys(queryParams).length){
      dispatch({
        type: actions.UTILS_LOADER,
        payload: true
      });
    }
    globalGetService(`contracts/${props.params.contract_slug}/utilization/`,{...queryParams})
    .then(response => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UTILS_LIST,
          payload: response.data.data
        });
      }
    })
  }
}

export const utilsMajorAssemblyAc = (props) => {
  return (dispatch) => {
    globalGetService(`contracts/${props.params.contract_slug}/major-assemblies/`,{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UTILS_MAJOR_ASSMEBLY,
          payload: response.data.data
        });
      }
    })
  }
}
// export const utilsViewAc = (slug,data) => {
//   return (dispatch) => {
//     if(data.editable){
//       globalGetService('contracts/'+slug+'/utilization/'+data.id+'/',{})
//       .then(response => {
//         if(response.data.statusCode == 200){
//           dispatch({
//             type: actions.UTILS_VIEW,
//             payload: response.data.data
//           });
//         }
//       })
//     }else{

//     }
//   }
// }

export const utilsInitiateAc = (props) => {
  return (dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    globalGetService(`contracts/${props.params.contract_slug}/utilizations/initialize/`,{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UTILS_INITIATE,
          payload: response.data.data
        })
        dispatch({
          type: actions.UTILS_LOADER,
          payload: false
        });
      }
    })
  }
}
export const addEditUtilizationAc = (params, contractSlug, data, assetViewId,shouldRedirect = true,generateInvoice) => {
  return (dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    globalPostService(`contracts/${contractSlug}/utilization/`,data)
    .then(response => {
      if(response.data.statusCode == 200){
        if(shouldRedirect){
        browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${contractSlug}/utilization`);
        toastFlashMessage(response.data.message, 'success');
        }
        if (typeof generateInvoice === 'function') {
            generateInvoice(); 
        }
        trackActivity('Item Added', {
          page_title: `Utilization`,
          application_module: `Contracts`,
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
          contract_slug: params.contract_slug,
          item_type: `MR Utilization`,
          item_id: response.data.data.id
        })
        dispatch(UtilsAssetViewAc(params, 'list'))
      }
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
    })
  }
}

export const editUtilsInfoAc = (slug, data, assetViewId, params, props) => {
  return (dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    globalPutService(`contracts/${props.params.contract_slug}/utilization/${data.id}/`,data)
    .then(response => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.UTILS_EDIT,
          payload: response.data.data
        });
        dispatch({
          type: actions.UTIL_CRUD_OPERN,
          payload: {type: '', data:{} , flag: false }
        });
        trackActivity('Item Edited', {
          page_title: `Utilization`,
          application_module: `Contracts`,
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
          contract_slug: props.params.contract_slug,
          item_type: `MR Utilization`,
          item_id: data.id
        })
        dispatch(utilsAvgAc(props))
        dispatch(UtilsAssetViewAc(params))
        toastFlashMessage(response.data.message, 'success');
        window.location.reload()
      }
    })
  }
}

export const exportUtilizationAc = (props, contract, fileType, util_type,aircraft, appliedFilter, ctrShortInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    let filters = {download: fileType, data: util_type}
    globalExportService(`contracts/${props.params.contract_slug}/utilization/`,{...filters, ...appliedFilter}, contract.slug, util_type+'_' , fileType)
    .then(response => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
      let fileName
      if (aircraft.asset_type === 2){
        downloadFileType(response, 'ESN'+aircraft.esn+'_'+ctrShortInfo.lessor_name.name+'_'+util_type+'.', fileType)
        fileName = 'ESN'+aircraft.esn+'_'+ctrShortInfo.lessor_name.name+'_'+util_type
      }else if(aircraft.asset_type === 3){
        downloadFileType(response, 'APU'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_'+util_type+'.', fileType)
        fileName = 'APU'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_'+util_type
      }else if(aircraft.asset_type === 4){
        downloadFileType(response, 'LG'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_'+util_type+'.', fileType)
        fileName = 'LG'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_'+util_type
      }else if(aircraft.asset_type === 5){
        downloadFileType(response, 'Propeller'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_'+util_type+'.', fileType)
        fileName = 'Propeller'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_'+util_type
      }else if(aircraft.asset_type === 1){
        downloadFileType(response, 'MSN'+aircraft.msn+'_'+ctrShortInfo.lessor_name.name+'_'+util_type+'.', fileType)
        fileName = 'MSN'+aircraft.msn+'_'+ctrShortInfo.lessor_name.name+'_'+util_type
      }else if (aircraft.asset_type === 6) {
        downloadFileType(response, ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type + '.', fileType)
        fileName = ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type
      }else if (aircraft.asset_type === 7) {
        downloadFileType(response, ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type + '.', fileType)
        fileName = ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type
      }else if (aircraft.asset_type === 8) {
        downloadFileType(response, ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type + '.', fileType)
        fileName = ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type
      }else if (aircraft.asset_type === 9) {
        downloadFileType(response, ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type + '.', fileType)
        fileName = ctrShortInfo.esn + '_' + ctrShortInfo.lessor_name.name + '_' + util_type
      }
      let assetInfo = aircraft;
      let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
      trackActivity('Report Exported',{
        page_title: 'Dashboard',
        application_module: 'Contracts',
        asset: asset_serial_number,
        file_name: 'Utilization',
        file_type: fileType.toUpperCase(),
      })
    });;
  }
}
export const exportRentalUtilizationAc = (props, contract, fileType, util_type,aircraft, appliedFilter, ctrShortInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.UTILS_LOADER,
      payload: true
    });
    let filters = {download: fileType, data: util_type}
    globalExportService('contracts/'+props.params.contract_slug+'/rental-utilization/',{...filters, ...appliedFilter}, contract.slug, util_type+'_' , fileType)
    .then(response => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: false
      });
      let fileName
      if (aircraft.asset_type === 2){
        downloadFileType(response, 'ESN'+aircraft.esn+'_'+ctrShortInfo.lessor_name.name+'_rental_'+util_type+'.', fileType)
        fileName = 'ESN'+aircraft.esn+'_'+ctrShortInfo.lessee.name+'_rental_'+util_type
      }else if(aircraft.asset_type === 3){
        downloadFileType(response, 'APU'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_rental_'+util_type+'.', fileType)
        fileName = 'APU'+aircraft.serial_number+'_'+ctrShortInfo.lessee.name+'_rental_'+util_type
      }else if(aircraft.asset_type === 4){
        downloadFileType(response, 'LG'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_rental_'+util_type+'.', fileType)
        fileName = 'LG'+aircraft.serial_number+'_'+ctrShortInfo.lessee.name+'_rental_'+util_type
      }else if(aircraft.asset_type === 5){
        downloadFileType(response, 'Propeller'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_rental_'+util_type+'.', fileType)
        fileName = 'PROPELLER'+aircraft.serial_number+'_'+ctrShortInfo.lessor_name.name+'_rental_'+util_type
      }else if(aircraft.asset_type === 1){
        downloadFileType(response, 'MSN'+aircraft.msn+'_'+ctrShortInfo.lessor_name.name+'_rental_'+util_type+'.', fileType)
        fileName = 'MSN'+aircraft.msn+'_'+ctrShortInfo.lessor_name.name+'_rental_'+util_type
      }else if (aircraft.asset_type === 6) {
        downloadFileType(response, 'FAN' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type + '.', fileType)
        fileName = 'FAN' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type
      }else if (aircraft.asset_type === 7) {
        downloadFileType(response, 'HPC' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type + '.', fileType)
        fileName = 'HPC' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type
      }else if (aircraft.asset_type === 8) {
        downloadFileType(response, 'HPT' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type + '.', fileType)
        fileName = 'HPT' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type
      }else if (aircraft.asset_type === 9) {
        downloadFileType(response, 'LPT' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type + '.', fileType)
        fileName = 'LPT' + aircraft.esn + '_' + ctrShortInfo.lessor_name.name + '_rental_' + util_type
      }
      let assetInfo = aircraft;
      let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
      trackActivity('Report Exported',{
        page_title: 'Dashboard',
        application_module: 'Contracts',
        asset: asset_serial_number,
        file_name: 'Rental Utilization',
        file_type: fileType.toUpperCase(),
      })
    });;
  }
}


export const utilFnCrudAc = (slug,data, type) => {
  return(dispatch) => {
      dispatch({
        type: actions.UTILS_LOADER,
        payload: true
      });
      globalGetService('contracts/'+slug+'/utilization/'+data.id+'/',{})
      .then(response => {
        dispatch({
          type: actions.UTILS_LOADER,
          payload: false
        });
        if(response.data.statusCode == 200){
          dispatch({
            type: actions.UTIL_CRUD_OPERN,
            payload: {type: type, data: response.data.data, flag: true }
          });
        }
      })
  }
}
