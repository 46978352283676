import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { globalExportService, globalGetService } from '../../../globalServices'
import checkApiStatus from '../../../utils'
import { useSnackbar } from 'notistack'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { LabelValueCard, PageLoader } from '../../../shared_elements'
import { checkPermission, downloadFileType, getCurrencyFormat } from '../../../utils_v2'
import moment from 'moment'
import NumericLabel from 'react-pretty-numbers';
import { backendDateFormat, displayDateFormat, displayMonthYear, fieldDateFormat } from '../../../constants'
import { browserHistory } from 'react-router'
const InvoiceItemList = ({ item, invoiceInfo }) => {
    return (
        <TableRow>
            <TableCell>
                <div>
                    {item?.asset || '--'}
                    <p>({item.invoice_number})</p>
                </div>
                </TableCell>
             {invoiceInfo.invoice_type.value===2?<TableCell>{item?.invoice_item_type_id?.name || '--'}</TableCell>:null}
             <TableCell>{invoiceInfo.invoice_type.value===2?`${item?.description || '--'}`:`Utilization rent for the month of : ${moment(invoiceInfo.end_date).format(displayMonthYear)}`}</TableCell>
            {/* <TableCell>{item?.unit_type || '--'}</TableCell> */}
            {/* <TableCell>{item?.no_of_unit || '--'}</TableCell> */}
            {/* <TableCell>{item?.rate_per_unit? parseFloat(item?.rate_per_unit).toFixed(2) : '--'}</TableCell> */}
            <TableCell>{item?.sub_total ? <NumericLabel params={getCurrencyFormat({currency: invoiceInfo.currency,shortFormat:false})}>{parseFloat(item?.sub_total).toFixed(2)}</NumericLabel>:'--'}</TableCell>
        </TableRow>
    )
}
export default function SummaryInvoiceView({ params }) {
    useEffect(() => {
        getInvoiceDetails()
    }, [])
    const [invoiceInfo, setInvoiceInfo] = useState({})
    const [isLoading, setLoading] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const getInvoiceDetails = () => {
        setLoading(true)
        globalGetService(`contracts/summary-invoice/${params.slug}/`)
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setInvoiceInfo(response.data.data)
                }
                else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                }
            })
    }
    const exportInvoice = () => {
        setLoading(true)
        globalExportService(`contracts/summary-invoice/${params.slug}/?download=pdf`)
            .then(response => {
                setLoading(false)
                downloadFileType(response, `Summary_invoice_${invoiceInfo?.invoice_number}`, 'pdf')
            })
    }
    return (
        <div className='summary-invoice-container'>
            {isLoading ? <PageLoader /> : null}
            <div className='smr-inv-pg'>
                <Grid container spacing={1}>
                    <Grid item sm='8'>
                        <div style={{cursor:"pointer",marginBottom:'15px'}}>
                            <div style={{display:'flex', alignItems:'center'}}> <ArrowBackIcon color='primary' onClick={()=>browserHistory.push('/invoices/?summary_invoice')} style={{marginRight:'4px'}} /> Summary Invoice</div>
                            <div style={{margin:'0px 25px',display:'flex', alignItems:'center'}}><h5 style={{fontWeight:'500'}}>Invoice #{invoiceInfo?.invoice_number}</h5></div>
                        </div>
                    </Grid>
                    <Grid item sm='4' style={{ textAlign: 'right' }}>
                    {checkPermission('contracts','summary_invoice','EXP')?
                        <Button size='small' style={{ marginBottom: '13px' }} variant="outlined" color="primary" onClick={() => { exportInvoice() }}>
                            Export
                        </Button>:null
                    }   
                    </Grid>
                </Grid>
                <Grid container style={{marginLeft:'24px'}}>
                    <LabelValueCard md={3} label='Invoice Number' value={invoiceInfo?.invoice_number || '--'} />
                    <LabelValueCard md={2} label='Invoice Status' value={invoiceInfo?.status?.label || '--'} />
                    <LabelValueCard md={2} label='Invoice Date' value={invoiceInfo?.start_date ? moment(invoiceInfo?.start_date).format(displayDateFormat) : '--'} />
                    <LabelValueCard md={2} label='Invoice Due Date' value={invoiceInfo?.due_date ? moment(invoiceInfo?.due_date).format(displayDateFormat) : '--'} />
                </Grid>
                <Grid container spacing={1} style={{ margin: "20px 0px" }}>
                    <Grid item sm='6' style={{ paddingRight: '25px' }}>
                        <div className='from-lessor-card'>
                            <h4 className="heading-block">From Lessor/Holdco</h4>
                            <div className='lessee-lessor-data'>
                            <LabelValueCard md={12} label='Name' value={invoiceInfo?.from_company_name || '--'} />
                                <LabelValueCard md={12} label='Address' value={invoiceInfo?.from_company_address || '--'} />
                                <LabelValueCard md={12} label='Contact (Email and Phone)' value={invoiceInfo?.from_company_contact || '--'} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm='6'>
                        <div className='from-lessor-card'>
                            <h4 className="heading-block">To (Lessee)</h4>
                            <div className='lessee-lessor-data'>
                                <LabelValueCard md={12} label='Name' value={invoiceInfo?.to_company_name || '--'} />
                                <LabelValueCard md={12} label='Address' value={invoiceInfo?.to_company_address || '--'} />
                                <LabelValueCard md={12} label='Contact (Email and Phone)' value={invoiceInfo?.to_company_contact || '--'} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className='receiavables-head'>
                    {invoiceInfo?.currency==='USD'? 'USD':invoiceInfo?.currency==='EUR'?'EUR':''} Recievables
                </div>
                <Table className='inv-smr-list' style={{marginBottom:'15px'}}>
                    <TableHead>
                        <TableRow >
                            <TableCell>Asset</TableCell>
                            {invoiceInfo.invoice_type?.value===2?<TableCell>Item</TableCell>:null}
                            <TableCell>Description</TableCell>
                            {/* <TableCell>Unit Description	</TableCell> */}
                            {/* <TableCell>No of Units</TableCell> */}
                            {/* <TableCell>Fixed Rate ({invoiceInfo?.currency==='USD'? 'USD':'EUR'})	</TableCell> */}
                            <TableCell>Sub Total ({invoiceInfo?.currency==='USD'? 'USD':invoiceInfo?.currency==='EUR'?'EUR':''})</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{
                        invoiceInfo?.invoice_items?.map((item, index) =>
                            <InvoiceItemList
                                item={item}
                                index={index}
                                invoiceInfo={invoiceInfo}
                            />)}
                    </TableBody>
                </Table>
                <div className='sub-total-inv-smr' style={{ textAlign: 'right' }}>
                    <h5 style={{marginBottom:'6px'}}>Billable Amount : {invoiceInfo?.currency==='USD'? 'US$':invoiceInfo?.currency==='EUR'?'€':''}{invoiceInfo?.amount ? parseFloat(invoiceInfo?.amount).toFixed(2) : '0.00'}</h5>
                    <h4>Total :{invoiceInfo?.currency==='USD'? 'US$':invoiceInfo?.currency==='EUR'?'€':''}{invoiceInfo?.amount ? parseFloat(invoiceInfo?.amount).toFixed(2) : '0.00'}</h4>
                </div>
                <div className='bank-details-head'>
                    <h5>Bank Detail For {invoiceInfo?.currency==='USD'? 'US$':invoiceInfo?.currency==='EUR'?'EUR':''} Rental Receivable</h5>
                </div>
                <Grid container spacing={1} className='bank-details-section-smr'>
                    <LabelValueCard md={3} label='Name' value={invoiceInfo?.bank_name || '--'} />
                    <LabelValueCard md={3} label='Account Name' value={invoiceInfo?.bank_account_name || '--'} />
                    <LabelValueCard md={3} label='Account Number' value={invoiceInfo?.bank_account_number || '--'} />
                    <LabelValueCard md={3} label='IBAN Code' value={invoiceInfo?.bank_iban || '--'} />
                    <LabelValueCard md={3} label='ABA Number' value={invoiceInfo?.aba_number || '--'} />
                    <LabelValueCard md={3} label='SWIFT Code' value={invoiceInfo?.bank_swift_code || '--'} />
                    <LabelValueCard md={3} label='SORT Code' value={invoiceInfo?.bank_sort_code || '--'} />
                    <LabelValueCard md={3} label='Footer Note' value={invoiceInfo?.footer_note || '--'} />
                </Grid>
            </div>
        </div>
    )
}
