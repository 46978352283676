import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { FleetGenericFilter } from '../../Elements'
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Divider, Button, Paper, TextField, Tabs, Tab } from '@material-ui/core';
import { TableListComp, PageLoader, ExportManu, EmptyCollection, FilterComponent } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { Switcher, FleetInvoice, InvoiceTypeGraph, InvoiceStatusGraph, InvoiceLesseeGraph, InvoiceLesseeTypeGraph } from '../components';
import { getPlatformConstantsLeaseStatusAc } from '../../../shared_elements/actionCreators';
import { convertFilterObject, removeEmptyKey, getLocalStorageInfo, checkPermission } from '../../../utils_v2';
import { getFleetInvoicesApi, exportFleetInvoicesApi, getInvoiceTypeGraphApi, geInvoiceStatusGraphApi, getLesseeTypeGraphApi, getInvoiceLesseeGraphApi, getAssetIdentifierApi, bulkInvoiceUpdateApi, generateSumInvoiceApi, getApplicableSummaryList } from '../apiServices';
import { globalFilterTypes, invoiceFleetFilterOps, invoiceStatus, invoiceType, invoiceFleetHd } from '../'
import { trackActivity } from '../../../utils/mixpanel';
import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
import { getLesseeFilterApi } from '../../Leases/apiServices';
import { Autocomplete } from '@material-ui/lab';
import { globalPutService } from '../../../globalServices';
import SummaryInvoice from '../components/SummaryInvoice';
import { browserHistory } from 'react-router';
class FleetInvoices extends Component {
  constructor(props){
    super(props);
    this.state = {
      skeletonLoader:false,
      skeletonLoaderInvType:false,
      skeletonLoaderInvStat: false,
      skeletonLoaderLesseeType: false,
      skeletonLoaderInvLessee: false,
      pageLoader:false,
      open:false,
      invoices:{ list:[], pagination:{} },
      sort: '',
      sort_by: '',
      filter: {},
      genericFilter:{},
      filterInvoiceType:{},
      filterInvoiceStatus:{},
      filterLesseeType:{},
      filterInvLessee:{},
      mode:'list',
      invoiceTypeGraph:{},
      invoiceStatGraph:{},
      invoiceLesseeTypeGraphData:{},
      invoicelesseeGraphData:{},
      assetTypeOptions:[],
      assetModelOptions:[],
      assetsOptions:[],
      lesseeOptions:[],
      lessorOptions:[],
      ownerOptions:[],
      lesseeFilter:[],
      bulkIds:[],
      bulkInvoiceStatus:{label:'Draft',value:'1'},
      tabIndex:this.props.location?.search.includes('summary_invoice')?'2': "1",
      fleetInvoiceState:1
    };
    this.getFleetInvoicesApi = getFleetInvoicesApi.bind(this);
    this.exportFleetInvoicesApi = exportFleetInvoicesApi.bind(this);
    this.getInvoiceTypeGraphApi = getInvoiceTypeGraphApi.bind(this);
    this.geInvoiceStatusGraphApi = geInvoiceStatusGraphApi.bind(this);
    this.getLesseeTypeGraphApi = getLesseeTypeGraphApi.bind(this);
    this.getInvoiceLesseeGraphApi = getInvoiceLesseeGraphApi.bind(this);
    this.getAssetIdentifierApi = getAssetIdentifierApi.bind(this);
    this.getLesseeFilterApi = getLesseeFilterApi.bind(this);
    this.bulkInvoiceUpdateApi = bulkInvoiceUpdateApi.bind(this);
    this.generateSumInvoiceApi = generateSumInvoiceApi.bind(this);
    this.getApplicableSummaryList = getApplicableSummaryList.bind(this);
  }
  componentDidMount(){
    const { genericFilter, filterInvoiceType, filterInvoiceStatus, filterLesseeType, filterInvLessee } = this.state;
    this.getFleetInvoicesApi(this.props, {per_page:50}, 'skeletonLoader');
    this.props.getPlatformConstantsLeaseStatus();
    this.getInvoiceTypeGraphApi(this.props, genericFilter, filterInvoiceType, 'skeletonLoaderInvType');
    this.geInvoiceStatusGraphApi(this.props, genericFilter, filterInvoiceStatus, 'skeletonLoaderInvStat');
    this.getLesseeTypeGraphApi(this.props, genericFilter, filterLesseeType, 'skeletonLoaderLesseeType');
    this.getInvoiceLesseeGraphApi(this.props, genericFilter, filterInvLessee, 'skeletonLoaderInvLessee');
    globalFilterTypes.map((item) => 
      this.getAssetIdentifierApi(item.label, item.type)
    )
    trackActivity('Page Visited',{
      application_module: 'Lease Management',
      page_title: 'Fleet Invoices List'
    });
    this.getLesseeFilterApi()
  }

  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getFleetInvoicesApi(this.props, { ...filter, sort: sortField, sort_by: 'desc', per_page:50 }, 'pageLoader');
      } else {
        this.getFleetInvoicesApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
      }
    } else {
      this.getFleetInvoicesApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page:50 }, 'pageLoader');
    }
  }
  changeGenericFilter = (e, keyParam, value) => {
    let query = Object.assign({},this.state.genericFilter);
    if(value){
      query = {
        ...query,
        [keyParam]:value.id
      }
    }else{
      delete query[keyParam];
    }
    this.setState({genericFilter: query});
    this.applyGenericFilter(query);
  }

  applyGenericFilter = (genericFilter) => {
    const { filterInvoiceType, filterInvoiceStatus, filterLesseeType, filterInvLessee } = this.state;
    this.getInvoiceTypeGraphApi(this.props, genericFilter, filterInvoiceType, 'skeletonLoaderInvType');
    this.geInvoiceStatusGraphApi(this.props, genericFilter, filterInvoiceStatus, 'skeletonLoaderInvStat');
    this.getLesseeTypeGraphApi(this.props, genericFilter, filterLesseeType, 'skeletonLoaderLesseeType');
    this.getInvoiceLesseeGraphApi(this.props, genericFilter, filterInvLessee, 'skeletonLoaderInvLessee');
  }
  onClickDataPointFn = (data, category, type) => {
    const { lesseeOptions, filterInvoiceType, filterInvoiceStatus, filterLesseeType, filterInvLessee, genericFilter, sort, sort_by } = this.state;
    if(type == 'basedOnType') {
      this.getFleetInvoicesApi(this.props, { ...filterInvoiceStatus, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, invoice_type:invoiceType.find(item => item.label === data).value}, 'pageLoader', 'underlyingData');
    }
    if (type == 'basedOnStatus') {
      this.getFleetInvoicesApi(this.props, { ...filterInvoiceType, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, invoice_status:invoiceStatus.find(item => item.label === data).value}, 'pageLoader', 'underlyingData');
    }
    if (type == 'basedOnStatusLessee') {
      this.getFleetInvoicesApi(this.props, { ...filterInvLessee, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, invoice_status:invoiceStatus.find(item => item.label === data).value , lessee_id:lesseeOptions.find(item => item.name === category).id}, 'pageLoader', 'underlyingData');
    }
    if (type == 'basedOnTypeLessee') {
      this.getFleetInvoicesApi(this.props, { ...filterLesseeType, ...genericFilter,  per_page:50, sort: sort, sort_by: sort_by, invoice_type:invoiceType.find(item => item.label === data).value, lessee_id:lesseeOptions.find(item => item.name === category).id}, 'pageLoader', 'underlyingData');
    }
  }
onBulkSelect = (item, flag)=>{
  if(flag){
    let ids = [...this.state.bulkIds, item]
    this.setState({bulkIds:ids})
  }
  else{
    this.setState({bulkIds:this.state.bulkIds.filter(id => id != item)})
  }
}
onBulkInvoiceStatus =(value)=>{
  this.setState({bulkInvoiceStatus:value})
  this.bulkInvoiceUpdateApi(this.state.bulkIds, this.props)
}
onChangetabs=(newValue)=>{
  if(newValue == 1){
    browserHistory.push('/invoices'); this.setState({tabIndex:newValue})
  }
  else{
    browserHistory.push('/invoices/?summary_invoice'); this.setState({tabIndex:newValue})
  }
}
  render(){
    const { open, skeletonLoader, skeletonLoaderInvType, skeletonLoaderInvStat, skeletonLoaderLesseeType, skeletonLoaderInvLessee,  pageLoader, filter, filterInvoiceType, filterInvoiceStatus, filterLesseeType, filterInvLessee, invoices, sort, sort_by, mode, invoiceTypeGraph, invoiceStatGraph, invoiceLesseeTypeGraphData, invoicelesseeGraphData, lesseeOptions, lessorOptions, ownerOptions, assetModelOptions, assetTypeOptions, assetsOptions, genericFilter, lesseeFilter,tabIndex } = this.state;
    const { pltConstantsLeaseStatus } = this.props;
    let filterOptions = Object.assign({}, invoiceFleetFilterOps);
    filterOptions = {
      ...filterOptions,
      lessee_id: {
        ...filterOptions.lessee_id,
        options: lesseeOptions
      },
      lease_status: {
        ...filterOptions.lease_status,
        options: pltConstantsLeaseStatus
      },
      lessor_id: {
        ...filterOptions.lessor_id,
        options: lessorOptions
      },
      owner_id: {
        ...filterOptions.owner_id,
        options: ownerOptions
      },
      asset_type: {
        ...filterOptions.asset_type,
        options: assetTypeOptions
      },
      asset_model: {
        ...filterOptions.asset_model,
        options: assetModelOptions
      },
      serial_number: {
        ...filterOptions.serial_number,
        options: assetsOptions
      }
    }
    let isJac = getLocalStorageInfo().defaultLessor.id === 442 ? true : false
    return(
      <section className="fleet-lease-management-sec">
        <DeploymentMessage/>
        { isJac && checkPermission('contracts','summary_invoice','R')?
        <Paper style={{marginBottom:'3px'}}>
        <Tabs
            value={tabIndex}
            onChange={(event, newValue) => { this.onChangetabs(newValue)}}
            indicatorColor="primary"
            textColor="primary"
            centered
            variant={'fullWidth'}
          >
             <Tab label="Fleet Invoice" value='1'/>
             <Tab label="Summary Invoice" value='2'/>
        </Tabs>
        </Paper>
        :null}
        {
          tabIndex == '1' && !this.props.location?.search.includes('summary_invoice') ?
          mode == 'list' ?
          <Fragment>
            { skeletonLoader ? <STableLoader count={8} />:
              <Fragment>
                <Paper square style={{padding:'6px 10px'}}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item md={8}>
                      <FilterComponent 
                        filter={filter}
                        filterMenu={filterOptions}
                        getResponseBack={(applyFilter) => {this.state.fleetInvoiceState == 1? this.getFleetInvoicesApi(this.props, {...applyFilter, page:1, per_page:invoices.pagination.per_page}, 'pageLoader'): this.getApplicableSummaryList({...applyFilter, page:1, per_page:invoices.pagination.per_page})}}
                      />
                    </Grid>
                    <Grid item md={4}>
                      {
                        this.state.bulkIds?.length ?
                        <div style={{display:'flex', justifyContent:isJac? 'space-between' : 'right' , alignItems:"center"}}>
                                  { isJac ? <Button onClick={() => {this.generateSumInvoiceApi(this.props, this.state.bulkIds, 'pageLoader');}} color='primary' variant='contained' size='small'>Generate Summary Invoice</Button> : null}
                           <div style={{minWidth:"190px"}}>
                            <Autocomplete
                              disableClearable={true}
                              style={{ marginTop: '4px' }}
                              options={[{ label: 'Sent', value: '2' }]}
                              getOptionLabel={option => option?.label}
                              value={this.state.bulkInvoiceStatus}
                              id="assignment_msn"
                              onChange={(e, value) => this.onBulkInvoiceStatus(e.target.value)}
                              renderInput={params => <TextField style={{ padding: '0px' }} {...params} label="Change Invoice status" placeholder="Draft" InputLabelProps={{ shrink: true }} variant="outlined" />}
                            />
                        </div>
                      </div>:
                      <ul className="list-inline" style={{float:'right', display:'flex'}}>
                        {/* {isJac ?
                          this.state.fleetInvoiceState == 1?
                          <Button style={{marginRight:'5px', height:'31px'}} color='primary' variant='outlined' size='small' onClick={()=>{this.getApplicableSummaryList()}}> Generate Invoice</Button>:
                          <Button style={{marginRight:'5px', height:'31px'}} color='primary' variant='outlined' size='small' onClick={()=>{this.getFleetInvoicesApi(this.props,{},'pageLoader')}}> Fleet List</Button>
                          : null
                        } */}
                        {invoices.list.length > 0 && checkPermission('contracts', 'invoices','EXP') &&
                          <li className="list-inline-item">
                            <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetInvoicesApi(this.props, {...filter, download:file.extension})} />
                          </li>
                        }
                        { checkPermission('contracts', 'invoices','ANA') ?
                          <li className="list-inline-item">
                            <Switcher 
                              mode={mode}
                              onChangeMode={(mode) => this.setState({mode:mode})}
                            />
                          </li>:null
                        }
                      </ul>
                      }
                    </Grid>
                  </Grid>
                </Paper>
                
                <TableListComp
                  heads={invoiceFleetHd}
                  sort={sort}
                  maxheight={isJac ? filter && Object.keys(filter).length > 3 ? window.innerHeight - 250 + 'px' : window.innerHeight - 220 + 'px':filter && Object.keys(filter).length > 3?window.innerHeight - 210 + 'px':''}
                  bulkOperation={false}
                  sort_by={sort_by}
                  data={invoices.list.map((item,index) =>
                    <FleetInvoice
                      bulkIds={this.state.bulkIds}
                      toggleBulkOps={this.onBulkSelect}
                      index={index}
                      item={item} />
                    )
                  }
                  createSortHandler={this.createSortHandler}
                  onChangePage={(event, newPage) =>  {this.state.fleetInvoiceState == 1? this.getFleetInvoicesApi(this.props, {...filter, page:newPage+1, per_page:invoices.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader') : this.getApplicableSummaryList({...filter, page:newPage+1, per_page:invoices.pagination.per_page, sort: sort, sort_by: sort_by})}}
                  onChangeRowsPerPage={(event) => {this.state.fleetInvoiceState == 1?  this.getFleetInvoicesApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader') : this.getApplicableSummaryList({...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}) }}
                  pagination={invoices.pagination}
                  noRecord={ invoices.list.length ? null:
                    <EmptyCollection
                      title="No records found"
                    />
                  }
                />
              </Fragment>
            }
          </Fragment> :
          <Fragment>
            <section className="fleet-lease-analytics-sec">
              <Paper className="generic-filter-block">
                <Grid container spacing={1}>
                  <Grid item xs={11} md={11}>
                    <FleetGenericFilter 
                      genericFilter={genericFilter}
                      lesseeOptions={lesseeOptions}
                      lessorOptions={lessorOptions}
                      assetTypeOptions={assetTypeOptions}
                      assetModelOptions={assetModelOptions}
                      assetsOptions={assetsOptions}
                      ownerOptions={ownerOptions}
                      changeGenericFilter={this.changeGenericFilter}
                      processingLoader={skeletonLoaderInvType || skeletonLoaderInvStat || skeletonLoaderLesseeType || skeletonLoaderInvLessee}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}>
                     <ul className='list-inline' style={{float:'right'}}>
                      <li className='list-inline-item'>
                        <Switcher 
                          mode={mode}
                          onChangeMode={(mode) => this.setState({mode:mode})}
                        />
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <InvoiceTypeGraph 
                    invoiceTypeGraph={invoiceTypeGraph} 
                    skeletonLoader={skeletonLoaderInvType}
                    filter={filterInvoiceType}
                    getResponseBack={(applyFilter)=>this.getInvoiceTypeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderInvType')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InvoiceStatusGraph 
                    invoiceStatGraph={invoiceStatGraph} 
                    skeletonLoader={skeletonLoaderInvStat}
                    filter={filterInvoiceStatus}
                    getResponseBack={(applyFilter)=>this.geInvoiceStatusGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderInvStat')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InvoiceLesseeGraph 
                    invoicelesseeGraphData={invoicelesseeGraphData} 
                    skeletonLoader={skeletonLoaderInvLessee}
                    filter={filterInvLessee}
                    getResponseBack={(applyFilter)=>this.getInvoiceLesseeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderInvLessee')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InvoiceLesseeTypeGraph 
                    invoiceLesseeTypeGraphData={invoiceLesseeTypeGraphData} 
                    skeletonLoader={skeletonLoaderLesseeType}
                    filter={filterLesseeType}
                    getResponseBack={(applyFilter)=>this.getLesseeTypeGraphApi(this.props, genericFilter, applyFilter, 'skeletonLoaderLesseeType')}
                    callExternalFunction={this.onClickDataPointFn} 
                  />
                </Grid>
              </Grid>
            </section>
          </Fragment>
          :
          <SummaryInvoice />
        }
        { open ? 
          <Dialog
            open={open}
            anchor="right"
            onClose={() => {this.setState({open:false});this.getFleetInvoicesApi(this.props, {per_page:50}, 'pageLoader');}}
            fullScreen
          >
            <DialogTitle id="form-dialog-title">
              Underlying Data
              <ul className='list-inline' style={{float:'right'}}>
                <li className='list-inline-item'>
                  <ExportManu title="Export" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Excel', extension: 'xls', key:''}, {title:'CSV', extension: 'csv', key:''}]} exportReportFn={(file) => this.exportFleetInvoicesApi(this.props, {...filter, download:file.extension})} />
                </li>
              </ul>
            </DialogTitle>
            <DialogContent dividers={true}>
                <TableListComp
                    heads={invoiceFleetHd}
                    data={invoices.list.map((item,index) =>
                    <FleetInvoice
                        index={index}
                        item={item} />
                    )
                    }
                    pagination={invoices.pagination}
                    onChangePage={(event, newPage) => this.getFleetInvoicesApi(this.props, {...filter, page:newPage+1, per_page:invoices.pagination.per_page, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    onChangeRowsPerPage={(event) => this.getFleetInvoicesApi(this.props, {...filter, page:1, per_page: event.target.value, sort: sort, sort_by: sort_by}, 'pageLoader')}
                    noRecord={ invoices.list.length ? null:
                      <EmptyCollection
                          title="No records found"
                      />
                    }
                />
            </DialogContent>
            <DialogActions style={{paddingLeft:'24px',paddingRight:'24px'}}>
                <Button onClick={() => {this.setState({open:false});this.getFleetInvoicesApi(this.props, {per_page:50}, 'pageLoader');}} color='primary' variant='outlined' size='small'>Close</Button>
            </DialogActions>
          </Dialog>:null    
        }
        { pageLoader ? <PageLoader/>:null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  pltConstantsLeaseStatus: state.shareReducer.pltConstantsLeaseStatus
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getPlatformConstantsLeaseStatus: () => dispatch(getPlatformConstantsLeaseStatusAc({constant_types:['lease_status']}))
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(FleetInvoices, ['contracts','invoices','R'])))
