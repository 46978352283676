import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link,withRouter } from 'react-router';
import { Row, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { Switch} from '@material-ui/core';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldSelect, FieldTextarea, FieldDate} from '../../Elements';
import { FormControlLabel, Checkbox} from '@material-ui/core';
import { checkString, checkValidDate, checkNumber } from '../../../../formValidator';
import { imgStoragePath, backendDateFormat } from '../../../../constants';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import {getDefaultBankName} from '../../../../lease_management/invoices/apiServices';
class EditLeaseType extends Component{
  constructor(props){
    super(props);
    this.state = {
      contract: {
        lease_type:'',
        contractual_start_date:null,
        contractual_end_date:null,
        lessee_id:'',
        operator:'',
        lease_term:'',
        mr_account_number:null,
        rental_account_number:null,
        same_operator_lessee: false,
        supplement_date:'',
        rolling_lease_span :'',
        rolling_lease_from:'',
        rolling_lease_status:false
        
      },
      mrRateFlag:false,
      rentalRateFlag:false,
      error: {},
      saveChange:false,
      bankList:[],
      durationType:[{label:"Days",value:1},{label:"Months",value:2}],
      isEnabled:false
    }
    this.getDefaultBankName= getDefaultBankName.bind(this)
  }
  componentDidMount(){
    this.updateLeaseState(this.props.contract)
    this.getDefaultBankName(this.props)
  }
  componentWillReceiveProps(nextProps){
    this.updateLeaseState(nextProps.contract)
  }

  updateLeaseState = (contract) => {
    let data = Object.assign({}, contract);
    data = {
      ...data,
      lease_type: data.lease_type.value,
      lease_type_accounting: data.lease_type_accounting.value
    }
    this.setState({
      contract: data
    });
  }
  onFieldChange = (e, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      contract: {
        ...prevState.contract,
        is_reserve_rate: data
      },
    }))
  }
  updateForm = (type, value) => {
     if (type === 'bank_details') {
      this.setState(prevState => ({
        contract: {
          ...prevState.contract,
          default_bank_id: value
        }
      }));
    }
    if(type == 'contractual_start_date' || type == 'contractual_end_date'){
      this.updateDate(type, value);
      if(type =="contractual_end_date" && value > this.props.contract.contractual_end_date){
        this.setState({
          mrRateFlag:true,
          rentalRateFlag:true
        })
      }
      if(type == "contractual_end_date" && value < this.props.contract.contractual_end_date){
        this.setState({
          mrRateFlag:false,
          rentalRateFlag:false
        })
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }
  }
  updateLeaseTerm =(keyParam, data)=>{
    const { contract } = this.state;
    this.setState(prevState => ({
      ...prevState,
      contract: {
        ...prevState.contract,
        [keyParam]: data
      },
    }));
    if(keyParam == 'lease_term' && moment(contract.contractual_start_date).isValid() ){
      this.updateDate('contractual_end_date', moment(contract.contractual_start_date).add(data, 'M').format(backendDateFormat))
    }
  }
  handleRadio = (checked, key) => {
      this.setState(prevState => ({
        ...prevState,
        contract:{
          ...prevState.contract,
          [key]: checked
        }
      }))
  }
  handleToggle = (checked,key) => {
    this.setState(prevState => ({
      ...prevState,
      contract:{
        ...prevState.contract,
        [key]: checked
      }
    }));
  };
  updateDate = (type, value) => {
    let leaseTerm = this.state.lease_term;
    if(type == 'contractual_start_date'){
      leaseTerm = moment(this.state.contract.contractual_end_date).diff(moment(value), 'months', true);
    }else{
      leaseTerm = moment(value).diff(moment(this.state.contract.contractual_start_date), 'months', true);
    }
    this.setState(prevState => ({
      ...prevState,
      contract:{
        ...prevState.contract,
        [type]: value,
        lease_term: Math.round(leaseTerm)
      },
      error: {
        ...prevState.error,
        [type]: '',
        lease_term: ''
      }
    }))
  }

  updateHdlineInfo =  (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.contract);
    data = {
      ...data,
      asset_id: this.state.contract.asset.id,
      lessor_id: this.state.contract.lessor.id,
      lessee_id: this.state.contract.lessee.id,
      operator_id: this.state.contract.operator ? this.state.contract.operator.id:null,
      lessor_portfolio: data.lessor_portfolio && data.lessor_portfolio.id ? data.lessor_portfolio.id:null,
      rental_escalation_interval: data.rental_escalation_interval && data.rental_escalation_interval.value? data.rental_escalation_interval.value:null,
      rental_interval: data.rental_interval && data.rental_interval.value? data.rental_interval.value:null,
      rental_type: data.rental_type && data.rental_type.value?data.rental_type.value:null,
      lease_status: data.lease_status && data.lease_status.value?data.lease_status.value:null,
      rental_account_number: data.rental_account_number?data.rental_account_number : null,
      mr_account_number: data.mr_account_number ? data.mr_account_number : null,
      supplement_date: data.supplement_date ? data.supplement_date : null ,
      rolling_lease_status:data.rolling_lease_status ? data.rolling_lease_status : 0,
      rolling_lease_span :data.rolling_lease_status === true ? data.rolling_lease_status ? data.rolling_lease_span : null : null,
      rolling_lease_from: data.rolling_lease_status === true ?  moment(data.contractual_end_date).add(1, 'days').format(backendDateFormat) : null,
      rolling_lease_duration: data.rolling_lease_status === true ? data.rolling_lease_span === 2 ? 1 : data.rolling_lease_duration : null, 
    }
    delete data['operator'];
    delete data['lessor'];
    delete data['asset'];
    delete data['lessee'];
    let validateNewInput = {
      lease_type: checkString({value: data.lease_type, required: true, minLength: '', maxLength: '', message: 'Please Select Lease Type'}),
      contractual_start_date: checkValidDate({value: data.contractual_start_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Start Date'}),
      contractual_end_date: checkValidDate({value: data.contractual_end_date, required: true, minLength: '', maxLength: '', message: 'Please Enter End Date '}),
      supplement_date: checkValidDate({value: data.supplement_date, required: false, minLength: '', maxLength: '', message: 'Please Enter Supplement Date'}),
      rolling_lease_from:data.rolling_lease_status === true  ? checkValidDate({value: data.rolling_lease_from, required: true, minLength: '', maxLength: '', message: 'Please Select From Date after Lease End date'}) : '',
      rolling_lease_span:data.rolling_lease_status === true ? checkValidDate({value: data.rolling_lease_span, required: true, minLength: '', maxLength: '', message: 'Please Select any Duration'}) : '',
    };
    if([2,3].includes(this.state.contract?.lease_duration_type?.value)){
      validateNewInput={
        ...validateNewInput,
        lease_duration_hours: checkNumber({value: data.lease_duration_hours, required: true, minLength: '', maxLength: '', message: 'Please Enter Lease Duration in Hours '}),
        tsn: checkNumber({value: data.tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Start TSN '}),
      }
      if(this.state.contract?.lease_duration_type?.value == 3){
        validateNewInput={
          ...validateNewInput,
          time_between_overhaul :  checkNumber({value: data.time_between_overhaul, required: true, minLength: '', maxLength: '', message: 'Please Enter Time between OverHaul '}),
          last_overhaul_tsn :  checkNumber({value: data.last_overhaul_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter last OverHaul TSN '}),
        }
      }
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.updateHdlineInfo(data);
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }
  render(){
    const { type, techConstants, hdLineLoader,autoInvoiceOn} = this.props;
    const { contract, error, mrRateFlag, rentalRateFlag,bankList} = this.state
     var bank_details = []
     if(bankList.bank_details){
      bankList.bank_details.map(item => {
         bank_details.push({id: item.id, name: item.name + ' (' + item.account_number + ')'})
       })
     }
    if(Object.keys(contract).length){
      return(
        <form onSubmit={(e) => this.updateHdlineInfo(e)}>
          <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
            <Row>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Type <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldSelect
                    placeholder='Select Lease Type'
                    keyParam="lease_type"
                    value={ contract.lease_type ? contract.lease_type: ''}
                    type={type}
                    options={techConstants.filter(item => item.type=="lease_type")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                <h6 className="error-msg">{error.lease_type}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Type (Accounting)</FieldLabel>
                  <FieldSelect
                    placeholder='Select Lease type (Accouting)'
                    keyParam="lease_type_accounting"
                    value={contract.lease_type_accounting?contract.lease_type_accounting:''}
                    type={type}
                    options={techConstants.filter(item => item.type=="lease_type_accounting")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Contract Number</FieldLabel>
                  <FieldInput
                    value={contract.contract_number ? contract.contract_number: ''}
                    type={type}
                    keyParam="contract_number"
                    updateField={this.updateForm}
                    maxLength={20}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Definition of Lease Period</FieldLabel>
                  <FieldTextarea
                    value={contract.contractual_definition_of_lease_period ? contract.contractual_definition_of_lease_period:''}
                    type={type}
                    keyParam="contractual_definition_of_lease_period"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Duration Type</FieldLabel>
                  <FieldInput
                    value={contract?.lease_duration_type ? contract.lease_duration_type.label: ''}
                    type={type}
                    disable
                    keyParam="lease_duration_type"
                    updateField={this.updateForm}
                    maxLength={20}
                  />
                </FieldGroup>
              </FieldCol>
              {this.props.contract.lease_duration_type?.value  === 1  && this.props.contract.lease_status?.value === 1 && contract?.contractual_end_date >= moment().format(backendDateFormat)  ?   
               <FieldCol md="12">
                <FieldGroup className="form-group">
                  <span>{contract.rolling_lease_status ? 'Enabled' : 'Disabled'}</span>
                  <Switch
                    checked={contract.rolling_lease_status}
                    onChange={(e)=>this.handleToggle(e.target.checked, 'rolling_lease_status')}
                    color="primary"
                  />
                  <span>Rolling Lease</span>
                  <InfoIcon id={"rollingLease"} color='primary' />
                  <ToolTipHover placement="top" tagetId={"rollingLease"}>
                    Click to enable Rolling Lease which will extend the lease as per selected Duration Type and will keep repeating until disabled.
                  </ToolTipHover>
                </FieldGroup>
              </FieldCol> : null }
              {contract.rolling_lease_status === true &&
                <><FieldCol md="4">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Duration Type <sup className="reqStar"> * </sup> </FieldLabel>
                    <FieldSelect
                      placeholder='Select Month or Days'
                      keyParam="rolling_lease_span"
                      type={type}
                      options={this.state.durationType}
                      value={contract.rolling_lease_span}
                      updateField={this.updateForm}
                      labelKey='label'
                      valueKey='value'
                    />
                     <h6 className="error-msg">{error.rolling_lease_span}</h6>
                  </FieldGroup>
                </FieldCol>
                  <FieldCol md="4">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label"> From  <sup className="reqStar"> * </sup> </FieldLabel>
                      <FieldDate
                        value={contract.rolling_lease_from ? contract.rolling_lease_from : moment(contract.contractual_end_date).add(1, 'days')}
                        type={type}
                        keyParam="rolling_lease_from"
                        updateField={this.updateForm}
                        minDate = {moment(contract.contractual_end_date).add(1, 'days')}
                        maxDate = {moment(contract.contractual_end_date).add(1, 'days') }
                      />
                      <h6 className="error-msg">{error.rolling_lease_from}</h6>
                    </FieldGroup>
                  </FieldCol>
                  {contract.rolling_lease_span === 1 ? <FieldCol md="4">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label"> No. of Days</FieldLabel>
                      <FieldInput
                        value={contract?.rolling_lease_duration}
                        keyParam="rolling_lease_duration"
                        updateField={this.updateForm}
                      />
                    </FieldGroup>
                  </FieldCol> : null
                  }
                  {contract.rolling_lease_span === 2 ? <FieldCol md="4">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label"> No. of Months</FieldLabel>
                      <FieldInput
                        value={contract.rolling_lease_span === 2 &&  1}
                        keyParam="rolling_lease_duration"
                        updateField={this.updateForm}
                        disable
                      />
                    </FieldGroup>
                  </FieldCol> : null
                  }
                </>}
              
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Start Date <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldDate
                    value={contract.contractual_start_date}
                    type={type}
                    keyParam="contractual_start_date"
                    updateField={this.updateForm}
                    disable={contract.rolling_lease_status === true}
                  />
                <h6 className="error-msg">{error.contractual_start_date}</h6>
                </FieldGroup>
              </FieldCol>

              {
                [3].includes(contract?.lease_duration_type?.value) ?
                null:
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease End Date <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldDate
                    value={contract.contractual_end_date}
                    type={type}
                    keyParam="contractual_end_date"
                    updateField={this.updateForm}
                    minDate={moment(contract.contractual_start_date).add(1, 'months')}
                    disable={contract.rolling_lease_status === true}
                  />
                <h6 className="error-msg">{error.contractual_end_date}</h6>
                </FieldGroup>
                {
                   mrRateFlag && rentalRateFlag ?
                  <FieldCol md="12">
                    <FieldGroup  style={{padding:'0 15px 10px 10px'}}>
                      <input type="checkbox"   onChange={(e) => this.handleRadio(e.target.checked, 'is_extend_mr_and_rental_date')}/>
                      <FieldLabel className="label" style={{marginRight:'10px', marginLeft:'5px'}}>Apply 'Lease end date' to MR and Rental Accounts
                        <img id="mrRateToolTip" src={imgStoragePath+'phase-2/info_grey.png'} style={{ position: 'relative', width: '14px' , left:'5px'}} /></FieldLabel>
                        <UncontrolledTooltip className="text-left" placement="right" target="mrRateToolTip">
                            <div style={{ textAlign: "left" }}>
                                <p>
                                  Selecting this option will change the 'MR Rate End Date' and 'Rental End Date' for applicable MR & Rental accounts of this contract
                                </p>
                            </div>
                        </UncontrolledTooltip>
                    </FieldGroup>
                  </FieldCol> : null
                }

              </FieldCol>
              }
              {
                [2, 3].includes(contract?.lease_duration_type?.value) ?
                <>
                <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Start TSN <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldInput
                    value={contract?.tsn ? contract.tsn: ''}
                    type={type}
                    accept='number'
                    keyParam="tsn"
                    updateField={this.updateForm}
                    maxLength={20}
                  />
                   <h6 className="error-msg">{error.tsn}</h6>
                </FieldGroup>
              </FieldCol>
                <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Duration (Hours) <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldInput
                    value={contract?.lease_duration_hours ? contract.lease_duration_hours: ''}
                    type={type}
                    accept='number'
                    keyParam="lease_duration_hours"
                    updateField={this.updateForm}
                    maxLength={20}
                  />
                 <h6 className="error-msg">{error.lease_duration_hours}</h6>
                </FieldGroup>
              </FieldCol>
              </>
              :null
              }
              {
                [3].includes(contract?.lease_duration_type?.value) ?
                  <>
                    <FieldCol md="12">
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">Time Between Overhaul <sup className="reqStar"> * </sup></FieldLabel>
                        <FieldInput
                          value={contract?.time_between_overhaul ? contract.time_between_overhaul : ''}
                          type={type}
                          accept='number'
                          keyParam="time_between_overhaul"
                          updateField={this.updateForm}
                          maxLength={20}
                        />
                          <h6 className="error-msg">{error.time_between_overhaul}</h6>
                      </FieldGroup>
                    </FieldCol>
                    <FieldCol md="12">
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">Last Overhaul TSN <sup className="reqStar"> * </sup></FieldLabel>
                        <FieldInput
                          value={contract?.last_overhaul_tsn ? contract.last_overhaul_tsn : ''}
                          type={type}
                          accept='number'
                          keyParam="last_overhaul_tsn"
                          updateField={this.updateForm}
                          maxLength={20}
                        />
                         <h6 className="error-msg">{error.last_overhaul_tsn}</h6>
                      </FieldGroup>
                    </FieldCol>
                  </>
                  :
                  null}


              {[3].includes(contract?.lease_duration_type?.value) ?
                null :
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Term (Months)</FieldLabel>
                  <FieldInput
                    value={contract.lease_term ? contract.lease_term: ''}
                    type={type}
                    keyParam="lease_term"
                    accept='number'
                    updateField={this.updateLeaseTerm}
                    style={{background: "#fff"}}
                  />
                  <h6 className="error-msg">{error.lease_term}</h6>
                </FieldGroup>
              </FieldCol>
              }
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Conditions Precedent</FieldLabel>
                  <FieldInput
                    value={contract.conditions_precedence ? contract.conditions_precedence:''}
                    type={type}
                    keyParam="conditions_precedence"
                    updateField={this.updateForm}

                  />
                  <h6 className="error-msg">{error.first_name}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Alternate Dispute Redressal</FieldLabel>
                  <FieldInput
                    value={contract.alternate_dispute_redressal ? contract.alternate_dispute_redressal:''}
                    type={type}
                    keyParam="alternate_dispute_redressal"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.first_name}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Rental Account Number</FieldLabel>
                  <FieldInput
                    value={contract.rental_account_number ? contract.rental_account_number:''}
                    type={type}
                    maxLength={50}
                    keyParam="rental_account_number"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.rental_account_number}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label"><span>MR Account Number<FormControlLabel
                      control={
                        <Checkbox
                        style={{ marginLeft: "15px" ,fontSize:"12px"}}
                        color="primary"
                        size='small'
                        checked={contract.is_reserve_rate ? contract.is_reserve_rate : false}
                        onChange={(e) => this.onFieldChange(e,'is_reserve_rate', e.target.checked)}
                        value="Reserve Rate Not Applicable"
                      />
                      }
                      label={<span style={{fontSize:"13px"}}>Reserve Rate Not Applicable</span>}
                    /></span>  </FieldLabel>
                  <FieldInput
                    value={contract.mr_account_number ? contract.mr_account_number:''}
                    type={type}
                    keyParam="mr_account_number"
                    maxLength={50}
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.mr_account_number}</h6>
                </FieldGroup>
              </FieldCol>
              {/* <FieldCol md ='12'>
              <FieldGroup className="form-group">
                  <FieldLabel className="label">Supplement Date</FieldLabel>
                  <FieldDate
                    value={contract.supplement_date}
                    type={type}
                    keyParam="supplement_date"
                    updateField={this.updateForm}
                    minDate={moment(contract.contractual_start_date)}
                    maxDate={moment(contract.contractual_end_date)}
                  />
                </FieldGroup>
              </FieldCol> */}
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Insurance Type
                  <InfoIcon id={"insurances"} />
                  <ToolTipHover placement="top" tagetId={"insurances"}>
                    Contingent or Possessed
                  </ToolTipHover>
                  </FieldLabel>
                  <FieldInput
                    value={contract.insurance_type ? contract.insurance_type:''}
                    type={type}
                    keyParam="insurance_type"
                    maxLength={30}
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              {autoInvoiceOn.auto_invoice_generate && <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Default Bank
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Bank' 
                    keyParam="bank_details"
                    value={contract.default_bank_id}
                    type={type}
                    options={bank_details}
                    updateField={this.updateForm}
                    labelKey= 'name'
                    valueKey='id'
                  />
                </FieldGroup>
              </FieldCol>}
            </Row>
         </div>
         <div className="submit-block">
           <input type="submit" className="primary-btn" value={hdLineLoader ? 'Processing...':"Save Changes"}/>
           <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
         </div>
       </form>
      )
    }else{
      return <h6>Loading ...</h6>
    }
  }
}

 export default withRouter(EditLeaseType);

